import React from "react";
import Layout from "../components/Layout";
import "../styles/global.css";
import "../styles/faq.css";
import "../styles/pageHeader.css";

const data = [
  {
    question: "How does the installation process work?",
    answer:
      "During installation, our specialists provide all services that require in-depth knowledge of the technology, such as design-related work. We also supervise the selection and mixture of the foam, fill the pressurized tank, and conduct tests.",
  },
  {
    question:
      "How much does corrosion and clogging affect the functionality of the Pi Foam System?",
    answer:
      "The Pi Foam System’s pipes contain no water and its design ensures that no external substance can enter from the Circular Injection Ring (CIR). This makes sediment-related malfunctions impossible. Moreover, the foam-storage equipment is either lined with corrosion-resistant material or has a passivated surface, meaning the foam will not cause corrosion. Once the system has been activated, we advise our clients to conduct regular anti-corrosion maintenance and to keep the pipes clean.",
  },
  {
    question:
      "Do I need to install an additional rim-seal fire protection system?",
    answer:
      "The Pi Foam System provides comprehensive protection for any tank regardless of size or type  – from rim-seal to full-surface fires. Previously installed conventional systems do not interfere with the Pi Foam System, but since it is more efficient and costs less, you would probably prefer to dismantle the older systems.",
  },
  {
    question: "Is the Pi Foam System explosion-resistant?",
    answer:
      "The pressure vessel and the retaining valve are preferably stored underground. The pipes running from the vessel to the storage tank are also buried to protect them against temperature changes, fire and mechanical damage. The pipes on the storage tank’s outside wall are fastened using heavy-duty pipe supports that are designed to withstand explosions inside the tank. In fixed-roof tanks, we build in a low-pressure gas-tight separation device to prevent hydrocarbon gases from infiltrating the pipe network.",
  },
  {
    question: "Are you the sole supplier of the foam?",
    answer:
      "No. We provide guidelines on the preferred characteristics of the foam concentrate, particularly with respect to temperature conditions. You can work with any of your preferred suppliers. During the installation, our certified specialists supervise the preparation of the ready-state pressurized foam (mixture, fill-up and pressurization) and conduct initial tests. We then offer comprehensive training for subsequent refills.",
  },
  {
    question:
      "Can the Pi Foam System protect against fires in the bundle area?",
    answer:
      "Yes. Outer areas are secured by a separate, independent system based on the same pressurized-foam mechanics. Chevron chose this kind of design for a storage-terminal project in Kuwait using the second-generation Pi Foam System.     ",
  },
];

function faq() {
  return (
    <Layout>
      <main className="faq__main page__container">
        <h1 className="page__title">FAQ</h1>
        <div className="faq__wrapper">
          {data.map((d) => {
            return (
              <section>
                <h2>{d.question}</h2>
                <p>{d.answer}</p>
              </section>
            );
          })}
        </div>
      </main>
    </Layout>
  );
}

export default faq;
